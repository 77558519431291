import React, { useEffect, useState } from "react";
import { BATTLE_STATUS } from "../../helper/constants";
import { Button, Card, Input, Table, Tabs, Typography } from "antd";
import { get } from "../../helper/api";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import Search from "antd/es/input/Search";

function Battles() {
  const [tab, setTab] = useState(BATTLE_STATUS.open);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchParams] = useSearchParams();
  const q = searchParams.get("status");
  const p = searchParams.get("page");

  const tabItems = [
    {
      key: BATTLE_STATUS.open,
      label: (
        <Typography.Text className="capitalize">
          {BATTLE_STATUS.open}
        </Typography.Text>
      ),
    },
    {
      key: BATTLE_STATUS.requested,
      label: (
        <Typography.Text className="capitalize">
          {BATTLE_STATUS.requested}
        </Typography.Text>
      ),
    },
    {
      key: BATTLE_STATUS.pending,
      label: (
        <Typography.Text className="capitalize">
          {BATTLE_STATUS.pending}
        </Typography.Text>
      ),
    },
    {
      key: BATTLE_STATUS.running,
      label: (
        <Typography.Text className="capitalize">
          {BATTLE_STATUS.running}
        </Typography.Text>
      ),
    },
    {
      key: BATTLE_STATUS.semi_pending,
      label: (
        <Typography.Text className="capitalize">
          {BATTLE_STATUS.semi_pending}
        </Typography.Text>
      ),
    },
    {
      key: BATTLE_STATUS.complete,
      label: (
        <Typography.Text className="capitalize">
          {BATTLE_STATUS.complete}
        </Typography.Text>
      ),
    },
    {
      key: BATTLE_STATUS.cancelled,
      label: (
        <Typography.Text className="capitalize">
          {BATTLE_STATUS.cancelled}
        </Typography.Text>
      ),
    },
  ];
  const columns = [
    // {
    //   title: "Id",
    //   dataIndex: "_id",
    //   key: "id",
    // },
    {
      title: "Creater",
      dataIndex: "createrId",
      key: "creater",
    },
    {
      title: "Joiner",
      dataIndex: "joinerId",
      key: "joiner",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Prize",
      dataIndex: "prize",
      key: "prize",
    },
    {
      title: "Date&Time",
      dataIndex: "date",
      key: "date",
    },
    ...(search && [{ title: "Status", dataIndex: "status", key: "status" }]),
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const getBattles = async () => {
    setLoading(true);
    let res;
    if (search !== "") {
      res = await get(`/battles?search=${search}&page=${page}&limit=${limit}`);
    } else {
      res = await get(`/battles?status=${tab}&page=${page}&limit=${limit}`);
    }
    if (res?.success) {
      setTotalData(res?.count);
      setDataSource(
        res?.battles?.map((b) => {
          return {
            ...b,
            date: (
              <Typography.Text className="text-xs ">
                {moment(b?.createdAt).format("DD-MM-YYYY, hh:mm a")}
              </Typography.Text>
            ),
            action: (
              <Button onClick={() => navigate(`/battles/${b?._id}`)}>
                View
              </Button>
            ),
          };
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getBattles();
  }, [tab, search, page, limit]);

  useEffect(() => {
    setTab(q || "open");
    setPage(p || 1);
  }, [q,p]);
  return (
    <>
      <Card
        tabList={!search && tabItems}
        activeTabKey={tab}
        title={
          <div className="flex items-center justify-between gap-2">
            <Typography.Text className="text-lg">Battles</Typography.Text>
            <div className="flex items-center gap-2">
              <Search
                placeholder="Search here..."
                onSearch={(e) => setSearch(e)}
                type="search"
                allowClear
              />
            </div>
          </div>
        }
        onTabChange={(e) => {
          navigate(`/battles?status=${e}&page=1`);
        }}
      >
        <div className="w-full overflow-x-auto">
          <Table
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            pagination={{
              onChange: (e) => navigate(`/battles?status=${tab}&page=${e}`),
              pageSize: limit,
              total: totalData,
              current: page,
            }}
          />
        </div>
      </Card>
    </>
  );
}

export default Battles;
