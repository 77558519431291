import { Button, Card, Form, Input, Modal, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { getTitle } from "../../helper/comp";
import { useForm } from "antd/es/form/Form";
import { get, put, uploadImg } from "../../helper/api";

function Settings() {
  const [form] = useForm();
  const [updating,setUpdating]=useState(false)
  const [modal,setModal]=useState(false)
  const [file,setFile]=useState()

  const [loading,setLoading]=useState(false)


  const handleImageChange=(e)=>{
    try {
      const img = e.target.files[0]
      setFile(img)
      console.log(img);

    } catch (error) {
      message.error(error.message)
    }
  }

  const uploadImage = async()=>{
    setLoading(true)
    if(!file){
      return message.error("Please select file")
    }
    const res = await uploadImg(file)
    if(res?.success){
form.setFieldValue("qr",res?.url)
setModal(false)
setFile(null)
message.success("File Uploaded successfully")
    }
    setLoading(false)
  }

const getSettings = async()=>{
    const res = await get("/app/get-settings")
    if(res?.success){
        form.setFieldsValue(res?.settings)
    }
}

const onSubmit =async(vals)=>{
setUpdating(true)
  const res = await put("/app/update-settings",vals)
  if(res?.success){
    form.setFieldsValue(res?.settings)
    message.success("Settings Updated")
  
  }   else{
    message.error(res?.message)
  }
  setUpdating(false)

}

useEffect(()=>{
getSettings()
},[])

  return (
    <>
    <Modal open={modal} onCancel={()=>setModal(false)} footer={false}>
<Input type="file" className="mt-9" onChange={handleImageChange}/>
<Button className="mt-2" type="primary" loading={loading} onClick={uploadImage}>Upload</Button>
    </Modal>

    <Card title={getTitle("Settings")}>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="Admin Commission" name={"admin_commission"}>
          <Input />
        </Form.Item>
        <Form.Item label="Refer Commission" name={"refer_commission"}>
          <Input />
        </Form.Item>
        <Form.Item label="Min Withdraw" name={"min_withdraw"}>
          <Input />
        </Form.Item>
        <Form.Item label="Max Withdraw" name={"max_withdraw"}>
          <Input />
        </Form.Item>
        <Form.Item label="Min Deposit" name={"min_deposit"}>
          <Input />
        </Form.Item>
        <Form.Item label="Max Deposit" name={"max_deposit"}>
          <Input />
        </Form.Item>
        <Form.Item label="WhatsApp Number" name={"whatsapp_number"}>
          <Input />
        </Form.Item>
        <Form.Item label="Name" name={"name"}>
          <Input />
        </Form.Item>
        <Form.Item label="Upi" name={"upi"}>
          <Input />
        </Form.Item>
        <div className="flex gap-2">
        <Form.Item label="Qr" name={"qr"} className="flex-1">
          <Input />
        </Form.Item>
        <Form.Item label="Upload" >
          <Button onClick={()=>setModal(true)}>Upload</Button>
        </Form.Item>
        </div>
        <Form.Item label="Add Money" name={"add_money"}>
          <Switch />
        </Form.Item>
        <Form.Item label="Phone Gateway" name={"gateway"}>
          <Switch />
        </Form.Item>
        <Form.Item label="Maintenance" name={"maintenance"}>
          <Switch />
        </Form.Item>
        <Button type="primary" className="btn-primary" htmlType="submit" loading={updating}>
          Update
        </Button>
      </Form>
    </Card>
    </>
  );
}

export default Settings;
